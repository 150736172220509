import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "C:\\_Website\\Remotetest\\app\\providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitch"] */ "C:\\_Website\\Remotetest\\components\\theme-switch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\_Website\\Remotetest\\elements\\RestartAppButton\\RestartAppButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "C:\\_Website\\Remotetest\\node_modules\\@nextui-org\\link\\dist\\index.mjs");
;
import(/* webpackMode: "eager" */ "C:\\_Website\\Remotetest\\styles\\globals.css");
;
import(/* webpackMode: "eager" */ "C:\\_Website\\Remotetest\\node_modules\\next\\font\\google\\target.css?{\"path\":\"config\\\\fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "C:\\_Website\\Remotetest\\node_modules\\next\\font\\google\\target.css?{\"path\":\"config\\\\fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
